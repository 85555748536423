'use client'
import React, { useEffect, useState } from 'react'
import ProductCard from '../ProductCard'
import Slider from '@/components/Slider'
import useCustomSWR from '@/hook/useCustomSWR'
import { useInView } from 'react-intersection-observer'
import { Skeleton } from '@mui/material'
const CountryCard = ({ country, style, length, selectedId, setSelectedId }) => {
    const { country_name, image, country_banner, used_country, _id } = country;
    const cardStyle = {
        backgroundImage: `url(${country_banner})`,
        backgroundSize: selectedId == _id && '200%', // Corrected
        transition: selectedId == _id && 'backgroundSize 0.5s' // Corrected
    };

    return (
        <div
            onMouseEnter={() => setSelectedId(_id)}
            className={`${style} ${selectedId == _id ? ' ' : 'filter grayscale'} relative flex justify-center items-center bg-no-repeat bg-cover bg-center cursor-pointer`}
            style={cardStyle} >
            <div className='relative z-10 flex flex-col text-xl sm:text-2xl md:text-3xl font-bold text-white '>
                <p>{country_name}</p>
                <p className='text-center'>{used_country}</p>
            </div>
            <div className='absolute inset-0 bg-black opacity-30' />
        </div>
    );
};


const CountrySection = () => {
    const [isVisible, setIsVisible] = useState(false)
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    });
    useEffect(() => {
        if (!inView) return
        setIsVisible(true)
    }, [inView])
    const [selectedId, setSelectedId] = useState('')
    const { data: countries, mutate } = useCustomSWR(`/v1/country/top-countries?page=1&limit=20`, inView);
    const { data, isLoading, isError, revalidate } = useCustomSWR(`/v1/products/get-all-products?filter[country_id][$eq]=${selectedId}&page=1&limit=6&random=yes`, inView);
    const countryList = countries?.data;
    const productList = data?.productList;
    useEffect(() => {
        if (countryList) {
            setSelectedId(countryList[0]?._id)
        }
    }, [countryList])

    if (!isVisible) return <div ref={ref}></div>
    return (
        <div ref={ref} className='flex flex-col'>
            <section className='w-full flex flex-col lg:flex-row items-center'>
                <Slider gap={'gap-0'}>
                    {countryList?.map((item, index) => {
                        const style = `shrink-0 h-40 sm:h-44 md:h-60 overflow-hidden w-32 sm:w-40 md:w-52 `;
                        return (
                            <CountryCard key={item._id} country={item} length={countryList.length} style={style} selectedId={selectedId} setSelectedId={setSelectedId} />
                        );
                    })}
                </Slider>

            </section >
            <section className=' py-5'>
                <Slider>
                    {
                        isLoading && Array.from({ length: 6 }, (_, index) => (
                            <div className='w-52 sm:w-60 md:w-80 h-52 md:h-96 2xl:h-[30rem] rounded-lg overflow-hidden'>
                                <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
                            </div>
                        ))
                    }
                    {
                        productList?.map(item => (
                            <ProductCard
                                key={item._id}
                                product={item}
                                style={'w-52 sm:w-60 md:w-80 shrink-0 h-52 md:h-96 2xl:h-[30rem]'}
                                iconStyle={`w-5 h-5 md:w-7 md:h-7`}
                                textSize={`text-xs md:text-sm`}
                                quickViewWidth={24}
                                quickViewHeight={24}
                            />
                        ))
                    }
                </Slider>
            </section>

        </div >
    )
}

export default CountrySection