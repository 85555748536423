import React, { useState } from "react";
import { useCart } from "@/context/cart/CartContext";
import Loader from "../loading-spinner";

const AddToCart = ({
  availableForSale,
  productId,
  className,
  iconColor,
  orderQty,
}) => {
  const [iconStyle, setIconStyle] = useState(iconColor);
  const [adding, setAdding] = useState(false);
  const { addToCart } = useCart();

  const handleAdd = (productId) => {
    setAdding(true);
    addToCart(productId, orderQty).then((data) => {
      if (data) {
        setAdding(false);
      } else {
        setAdding(false);
      }
    });
  };

  const handleMouseEnter = () => {
    setIconStyle("#ffffff");
  };

  const handleMouseLeave = () => {
    setIconStyle(iconColor);
  };

  return (
    <div className="flex relative  items-center  justify-center   overflow-hidden font-medium text-black transition duration-300 ease-out border border-black pl-2 mx-2 rounded-full shadow-md group">
      <svg
        class="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
        ></path>
      </svg>
      <button
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
        aria-label="Add item to cart"
        onClick={() => handleAdd(productId)}
        className={`text-sm ${className} ${
          !availableForSale && "pointer-events-none bg-gray-400 text-white"
        } relative inline-flex items-center justify-center p-4 px-6 py-2 overflow-hidden font-medium text-black transition duration-300 ease-out rounded-full shadow-md group`}
      >
        <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-gray-300 group-hover:translate-x-0 ease">
          <p className="text-black">Great Choice!</p>
        </span>
        <span class="absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease">
          {availableForSale ? "Add To Cart" : "Request Availability"}
        </span>
        <span class="relative invisible">Great Choice!</span>

        {/* <span className='text-xs md:text-base'>{availableForSale ? 'Add To Cart' : 'Out Of Stock'}</span> */}
        {adding ? <Loader className={"w-4 h-4 absolute right-2"} /> : null}
      </button>
    </div>
  );
};

export default AddToCart;
