"use client";
import React, { useState, useEffect } from "react";
import Card from "@/components/product/Card";
import useCustomSWR from "@/hook/useCustomSWR";
import Link from "next/link";
import { Baskervville, Open_Sans } from "next/font/google";
import { useInView } from "react-intersection-observer";
import { Skeleton } from "@mui/material";
const baskervville = Baskervville({
    weight: ["400"],
    style: ["normal", "italic"],
    subsets: ["latin"],
    display: "swap",
});

const open_sans = Open_Sans({
    weight: ["400", "700", "800"],
    style: ["normal", "italic"],
    subsets: ["latin"],
    display: "swap"
})

const ProductSection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    });

    useEffect(() => {
        if (!inView) return;
        setIsVisible(true);
    }, [inView]);

    const { data, isLoading, isError, revalidate } = useCustomSWR(
        `/v1/products/get-all-products?page=1&limit=20&random=true`, inView
    );
    const products = data?.productList;
    if (!isVisible) return <div ref={ref}></div>
    return (
        <div
            ref={ref}
            className="flex flex-col">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4  gap-5 mt-2">
                {
                    isLoading && Array.from({ length: 12 }, (_, index) => (
                        <div className="w-full h-52 md:h-96 2xl:h-[30rem] rounded-lg overflow-hidden">
                            <Skeleton
                                animation="wave"
                                variant="rectangular" width="100%" height="100%" />
                        </div>
                    ))
                }
                {products?.length !== 0 &&
                    products?.map((product) => (
                        <Card key={product._id} product={product} />
                    ))}
            </div>
            <div className="flex flex-col py-10">
                <h1 className={`${baskervville.className} text-black text-center text-7xl leading-snug font-normal`}>
                    <span className={`font-extrabold font-sans ${open_sans.className}`}>OK,</span> <br />
                    show me <span className={`font-bold font-sans ${open_sans.className}`}>all</span> <br />
                    products!
                </h1>
            </div>
            <Link href={'/product'} className="text-lg flex justify-center items-center  uppercase underline" >Discover More {` -->`}</Link>
        </div>
    )
};

export default ProductSection;
