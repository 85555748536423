'use client'
import React, { useState, useEffect } from 'react'
import '../css/home.css'
import DisplayHeadingImage from '../DisplayHeadingImage'
import BASE_URL from '@/components/constants'
import ProductCard from '../ProductCard'
import RedirectLink from '../RedirectLink'
import { useInView } from 'react-intersection-observer'
import useCustomSWR from '@/hook/useCustomSWR'
import { Skeleton } from '@mui/material'
const CategoriesList = ({ listData }) => {
    const [isVisible, setIsVisible] = useState(false)
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    })
    useEffect(() => {
        if (!inView) return
        setIsVisible(true)
    }, [inView])

    const [sellerId, setSellerId] = useState()
    useEffect(() => {
        if (listData[0]?._id) {
        }
    }, [listData])
    const { data, isLoading, isError, revalidate } = useCustomSWR(`/v1/products/get-all-products?filter[seller_id][$eq]=${sellerId}&page=1&limit=8&random=yes`, inView);
    const productList = data?.productList;
    const handleStartup = (id) => {
        setSellerId(id)

    }
    if (!isVisible) return <div ref={ref}></div>
    return (
        <div ref={ref} className='flex flex-col'>
            <div className='home-heading'>
                {
                    listData &&
                    listData.map((startup) => (
                        <DisplayHeadingImage
                            key={startup._id}
                            data={startup}
                            handleChange={handleStartup}
                            id={sellerId}
                        />
                    ))
                }
            </div>
            <div className='home-product-section'>
                {
                    isLoading &&
                    Array.from({ length: 8 }, (_, index) => (
                        <div className='w-full h-52 md:h-96 2xl:h-[30rem] rounded-lg overflow-hidden'>
                            <Skeleton
                                animation='wave'
                                variant='rectangular'
                                width='100%'
                                height='100%'
                            />
                        </div>
                    ))
                }
                {
                    productList?.length !== 0 &&
                    productList?.map((product) => (
                        <ProductCard
                            key={product._id}
                            product={product}
                            style={'w-full h-52 md:h-96 2xl:h-[30rem]'}
                            iconStyle={`w-7 h-7`}
                            textSize={`text-sm`}
                            quickViewWidth={24}
                            quickViewHeight={24}
                        />
                    ))

                }

            </div>
            {
                productList?.length !== 0 &&
                <RedirectLink
                    name='startup'
                    id={sellerId}
                    section={'Startup'}
                    listData={listData}
                />
            }
        </div>
    )
}

export default CategoriesList