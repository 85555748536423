'use client'
import React, { useState, useEffect } from 'react'
import { useAuth } from '@/context/AuthContext'
import authCheck from '@/hook/authCheck'
import ProductCard from '../ProductCard'

const HotDeal = ({ hotDeal }) => {
    const { isSignInOpen } = useAuth();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        const checkAuth = async () => {
            const auth = await authCheck();
            setIsAuthenticated(auth);
        };
        checkAuth();
    }, [isSignInOpen]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    if (!isAuthenticated || !hotDeal?.length) {
        return null
    }
    return (
        <div className="deals-card w-full flex  flex-col lg:flex-row items-center">

            <div className="deals-container1 w-full flex md:justify-center md:p-5 lg:w-2/5 ">
                <h1 className="home-item font-normal  text-5xl  lg:text-[4.74rem] leading-[4.74rem] text-start "> We picked some <span className="font-bold text-blue-500">cool things</span> for you!*</h1>
            </div>
            <div className="deals-container2  w-full lg:w-3/5 pr-2 flex flex-col">

                <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-5 text-[ #000000]">
                    {hotDeal?.map((product) => {
                        return (
                            <ProductCard
                                key={product._id}
                                product={product}
                                style={'w-full h-56 md:h-52 2xl:h-80'}
                                iconStyle={`w-4 h-4`}
                                textSize={`text-xs`}
                                quickViewWidth={20}
                                quickViewHeight={20}

                            />

                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HotDeal