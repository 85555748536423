import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/awards/AwardsList.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/Banner.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/brands/BrandsList.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/Carousal.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/category/CategoriesList.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/country/CountrySection.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/hotdeal/HotDeal.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/product/ProductSection.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/ProductCard.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/startup/StartupSection.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/utilities/UtilitiesList.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/utilities/UtilitiesList2.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/Video.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/text-reveal-card.jsx")