'use client'
import React, { useState, useEffect, useMemo } from 'react'
import useCustomSWR from '@/hook/useCustomSWR'
import DisplayHeadingImage from '../DisplayHeadingImage'
import BASE_URL from '@/components/constants'
import ProductCard from '../ProductCard'
import RedirectLink from '../RedirectLink'
import Slider from '@/components/Slider'
import { useInView } from 'react-intersection-observer';
import { Skeleton } from '@mui/material'
const BrandsList = ({ listData }) => {
    const [isVisible, setIsVisible] = useState(false)
    const { ref, inView, entry } = useInView({
        /* Optional options */
        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    });
    useEffect(() => {
        if (!inView) return
        setIsVisible(true)
    }, [inView])
    const [brandId, setBrandId] = useState()
    useEffect(() => {
        if (listData[0]?._id) {
            setBrandId(listData[0]?._id)
        }
    }, [listData])
    const { data, isLoading, isError, revalidate } = useCustomSWR(`/v1/products/get-all-products?filter[brand_id][$eq]=${brandId}&random=yes&page=1&limit=5`, inView);
    const productList = data?.productList;
    const handleBrand = (id) => {
        setBrandId(id)
    }

    const aboutBanner = useMemo(() => {
        const banner = listData?.find(item => item._id === brandId);
        return banner?.about_banner;
    }, [listData, brandId]);
    if (!isVisible) return <div ref={ref}></div>
    return (
        <div ref={ref} className='flex flex-col'>
            <div className='home-heading w-full'>
                <Slider>
                    {
                        !listData && Array.from({ length: 5 }, (_, index) => (
                            <div className='w-16 h-16 xl:w-20 xl:h-20'>
                                <Skeleton animation="wave" variant="circular" width="100%" height="100%" />
                            </div>

                        ))
                    }
                    {listData?.map((brand) => (
                        <DisplayHeadingImage
                            key={brand._id}
                            data={brand}
                            handleChange={handleBrand}
                            id={brandId}
                        />
                    ))}
                </Slider>
            </div>
            <div className='w-full'>
                <div className='home-product-section'>
                    {
                        isLoading && Array.from({ length: 5 }, (_, index) => (
                            <div className='w-full h-52 md:h-96 2xl:h-[30rem] rounded-lg overflow-hidden'>
                                <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
                            </div>
                        ))
                    }
                </div>

                {productList?.length !== 0 && (
                    <div className='home-product-section'>
                        <div className='h-52 md:h-96 2xl:h-[30rem] overflow-hidden'>
                            <img src={aboutBanner} alt="Banner" className='w-full h-full object-contain aspect-square' />
                        </div>
                        {productList?.map((product, index) => (
                            <ProductCard
                                key={product._id}
                                product={product}
                                style={'w-full h-52 md:h-96 2xl:h-[30rem]'}
                                iconStyle={`w-5 h - 5 md: w - 7 md: h - 7`}
                                textSize={`text - xs md: text - sm`}
                                quickViewWidth={24}
                                quickViewHeight={24}
                            />
                        ))}
                    </div>
                )}
            </div>
            {productList?.length !== 0 && (
                <RedirectLink
                    name='brand'
                    id={brandId}
                    section={'Brand'}
                    listData={listData}
                />
            )}
        </div>
    )
}

export default React.memo(BrandsList);
