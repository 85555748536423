import BASE_URL from "@/components/constants";
import getToken from "./getToken";
const authCheck = async () => {
    try {
        const accessToken = getToken();
        if (!accessToken) return;
        // Replace with your actual access token
        const response = await fetch(`${BASE_URL}/v1/auth/b2c/me`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

export default authCheck;
