import React from 'react'
import Image from 'next/image'
const UtilityHeading = ({ data, handleChange, id }) => {
    const { _id, name, image } = data
    return (
        <div
            onMouseEnter={() => handleChange(name)}
            onClick={() => handleChange(name)}

            className='flex flex-col '>
            <div className={`shrink-0 h-44 2xl:h-52  overflow-hidden cursor-pointer rounded-lg shadow ${(id === name) ? 'border-2 border-solid border-blue-500 ' : 'filter grayscale '}`}>
                <Image src={image} alt={name} width={400} height={400} className='w-full h-full object-cover' />
            </div>
            <h2 className='text-xl text-center font-bold py-2'><span className='text-[#FFAE5D]'>{name}</span></h2>
        </div>
    )
}

export default UtilityHeading